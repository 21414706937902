import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import PATHS from 'src/utils/paths'

import { getIsAuthorised, isAuthLoaded } from 'src/features/auth/duck/selectors'

const withPrivatePage = BaseComponent => props => {
  //  REDUX
  const isAuthorised = useSelector(getIsAuthorised)
  const isLoaded = useSelector(isAuthLoaded)

  useEffect(() => {
    isLoaded && !isAuthorised && navigate(PATHS.homepage)
  }, [])

  return <BaseComponent {...props} />
}

export default withPrivatePage
