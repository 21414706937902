import styled from 'styled-components'

export default styled.div`
  .web {
    display: block;

    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
  .web-inline {
    display: inline-block;

    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }

  .mobile {
    display: none;

    ${props => props.theme.breakpointsMedia.mobile} {
      display: block;
    }
  }
  
  .mobile-inline {
    display: none;

    ${props => props.theme.breakpointsMedia.mobile} {
      display: inline-block;
    }
  }
`
