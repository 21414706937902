import { API_STATES } from 'src/utils/api'
import { propEq, propOr, pipe, sortBy, prop, reverse, pathOr, isEmpty } from 'ramda'
import { createSelector } from 'reselect'
import { transformToArray } from 'src/utils/redux'

export const selectOrganisations = state => state.organisations

export const isOrganisationsLoading = createSelector(
  selectOrganisations,
  propEq('state', API_STATES.IN_PROGRESS)
)

export const getOrganisations = createSelector(
  selectOrganisations,
  pipe(
    propOr({}, 'organisations'),
    transformToArray,
    sortBy(
      prop('createdAt')
    ),
    reverse
  )
)

export const getOrganisationsMeta = createSelector(
  selectOrganisations,
  propOr({}, 'meta')
)

export const getOrganisationsDetailsById = createSelector(
  selectOrganisations,
  (state, id) => id,
  (organisations, id) => {
    const organisation = pathOr({}, ['organisations', id])(organisations)
    return isEmpty(organisation) ? organisation : organisation.toObject()
  }
)
