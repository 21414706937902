import { pipe, propOr, ifElse, prop, always } from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { AVATARS } from 'src/utils/avatar'

const hasAvatarUrlAttached = pipe(propOr(null, 'avatarUrl'), isNotNilOrEmpty)
const hasAvatarAttached = pipe(propOr(null, 'avatar'), isNotNilOrEmpty)
const getAvatarImage = avatarName => AVATARS[avatarName]

export const getUserPhotoOrAvatar = pipe(
  ifElse(
    hasAvatarUrlAttached,
    prop('avatarUrl'),
    ifElse(
      hasAvatarAttached,
      pipe(prop('avatar'), getAvatarImage),
      pipe(always('avatar_3'), getAvatarImage)
    )
  )
)
