import styled from 'styled-components'
import React from 'react'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import MuiTableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'

export const TableHeaderCell = styled(MuiTableCell)`
  word-break: keep-all;
`

export const TableCell = styled(MuiTableCell)`
  word-break: keep-all;
`

const TableContainer = styled.div`
  display: inline-block;
  width: 100%;
  overflow: auto;
`

export const Table = props => (
  <TableContainer>
    <MuiTable {...props}>{props.children}</MuiTable>
  </TableContainer>
)

export { TableBody, TableHead, TableRow, TableFooter }
