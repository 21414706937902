import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { map } from 'ramda'

import {
  getOrganisationUsers,
  getOrganisationUsersMeta
} from 'src/features/users/duck/selectors'
import {
  getOrganisationUsersRoutine
} from 'src/features/users/duck/actions'

import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableHead,
  TableRow,
  TableFooter
} from 'src/components/atoms/Table/Table'
import Pagination from 'src/components/atoms/Table/Pagination'
import EmptyState from 'src/components/EmptyState'
import UserAvatar from 'src/components/UserAvatar'

export const UsersList = props => {
  const { id } = props

  //  REDUX
  const dispatch = useDispatch()
  const users = useSelector(getOrganisationUsers)
  const usersPagination = useSelector(getOrganisationUsersMeta)

  const fetchOrganisationUsers = useCallback(
    query => dispatch(getOrganisationUsersRoutine({ id, query })),
    [dispatch]
  )

  const { t } = useTranslation()

  const UserRow = user => (
    <TableRow key={user.id}>
      <TableCell><UserAvatar size={30} user={user} /></TableCell>
      <TableCell>{user.firstName}</TableCell>
      <TableCell>{user.lastName}</TableCell>
      <TableCell>{user.nickname}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{t(`userRoles.${user.role}`)}</TableCell>
      <TableCell>{user.phoneNumber}</TableCell>
      <TableCell>{t(`jobFunction.${user.jobFunction}`)}</TableCell>
    </TableRow>
  )

  const TableEmptyState = (
    <TableRow>
      <TableCell colSpan={8}>
        <EmptyStateContainer>
          <EmptyState text={t('emptyStates.noData')} />
        </EmptyStateContainer>
      </TableCell>
    </TableRow>
  )

  const renderRows = map(UserRow)(users)

  return (
    <Table aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableHeaderCell />
          <TableHeaderCell>
            {t('organisationsDetailsPage.tableHeadings.firstName')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsDetailsPage.tableHeadings.lastName')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsDetailsPage.tableHeadings.nickname')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsDetailsPage.tableHeadings.email')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsDetailsPage.tableHeadings.role')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsDetailsPage.tableHeadings.phoneNumber')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsDetailsPage.tableHeadings.jobFunction')}
          </TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>{users.length > 0 ? renderRows : TableEmptyState}</TableBody>
      <TableFooter>
        <TableRow>
          <Pagination
            pagination={usersPagination}
            fetchRecords={fetchOrganisationUsers}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default UsersList

const EmptyStateContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
