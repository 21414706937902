import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PATHS from 'src/utils/paths'
import Navigation from 'src/components/Navigation/Navigation'
import styled from 'styled-components'
import withLocation from 'src/hoc/withLocation'
import i18n from 'src/providers/i18n'

import { getIsAuthorised } from 'src/features/auth/duck/selectors'

export const AuthLayout = props => {
  //  REDUX
  const isAuthorised = useSelector(getIsAuthorised)
  const { navigate } = props

  useEffect(() => {
    !isAuthorised && navigate(PATHS.homepage)
  }, [])

  return (
    <LayoutContainer>
      <Navigation />
      <Content>
        {props.children}
      </Content>
    </LayoutContainer>
  )
}

export default withLocation(AuthLayout)

const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
`

const Content = styled.div`
  flex-grow: 1;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: ${props => props.theme.palette.common.catskillWhite};
`
