import moment from 'moment'
import { capitalizeFirst } from 'src/utils/string'

export const FORMATS = {
  dashed: 'DD-MM-YYYY',
  slash: 'DD/MM/YYYY'
}

export const formatDate = (date, format) => moment(date).format(format)
export const takeLastMinuteDigit = date => Number(moment(date).format('mm'))
export const dateIsNotFromCurrentMonth = date =>
  moment(date).isBefore(moment().startOf('month'))
export const currentYear = moment().year()
export const currentYearMonth = moment().format('YYYY/MM')

export const formOptionsOfMonthsBetweenDates = (dateFrom, dateTo) => {
  const dateStart = moment(dateFrom)
  const dateEnd = moment(dateTo)
  let timeValues = []

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    const getLabel = dateStart.format('MMMM YYYY')

    timeValues.push({
      label: capitalizeFirst(getLabel),
      value: dateStart.format('YYYY/MM')
    })
    dateStart.add(1, 'month')
  }

  return timeValues
}

export const daysFromNowOrDate = date =>
  moment(date).isBefore(moment().subtract(3, 'days'))
    ? formatDate(date, FORMATS.dashed)
    : moment(date).fromNow()
