import React from 'react'

import DeviceDetector from 'src/components/DeviceDetector'
import NavigationWeb from 'src/components/Navigation/NavigationWeb'
import NavigationMobile from 'src/components/Navigation/Navigation.mobile'

export const Navigation = props => {
  const { open, toggleDrawer } = props

  return (
    <>
      <DeviceDetector>
        <div className='web'>
          <NavigationWeb open={open} toggleDrawer={toggleDrawer} />
        </div>
        <div className='mobile'>
          <NavigationMobile />
        </div>
      </DeviceDetector>
    </>
  )
}

export default Navigation
