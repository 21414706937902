import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PATHS from 'src/utils/paths'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import { APP_NAME } from 'src/utils/app'

import Company from 'src/components/icons/Company'
import {
  NavigationMobile as StyledNavigationMobile,
  SideNavListItem
} from 'de-suite-me-ui'
import { getAuthUser } from 'src/features/auth/duck/selectors'
import { logoutSupervisorRoutine } from 'src/features/auth/duck/actions'
import BubbleUserAvatar from 'src/components/BubbleUserAvatar'
import Logout from 'src/components/icons/Logout'
import withLocation from 'src/hoc/withLocation'

const NavigationMobile = props => {
  const { location: { pathname } } = props
  //  REDUX
  const dispatch = useDispatch()
  const authUser = useSelector(getAuthUser)
  const logoutUser = useCallback(() => dispatch(logoutSupervisorRoutine()), [
    dispatch
  ])

  const { t } = useTranslation()

  const navigateToPath = path => () => navigate(path)
  const isItemActive = path => pathname === path

  return (
    <StyledNavigationMobile
      onLogoClick={navigateToPath(PATHS.dashboard)}
      appName={APP_NAME}
      rightElement={<BubbleUserAvatar onClick={navigateToPath(PATHS.profile)} user={authUser} />}
      drawerBottomElements={<div>
        <SideNavListItem
          onClick={logoutUser}
          icon={Logout}
          text={t('authNavigation.logout')}
        />
      </div>}
    >
      <SideNavListItem
        isActive={isItemActive(PATHS.organisations)}
        onClick={navigateToPath(PATHS.organisations)}
        icon={Company}
        text={t('authNavigation.organisations')}
      />
    </StyledNavigationMobile>
  )
}

export default withLocation(NavigationMobile)
