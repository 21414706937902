import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { getOrganisationDetailsRoutine } from 'src/features/organisations/duck/actions'

import Grid from 'src/components/atoms/Grid'
import OrganisationDetails from 'src/features/organisations/components/OrganisationDetails'
import UsersList from 'src/features/users/OrganisationUsers'
import Paper from 'src/components/atoms/Paper'

export const OrganisationPage = (props) => {
  const { id } = props
  const { t } = useTranslation()

  // REDUX
  const dispatch = useDispatch()
  const fetchOrganisationDetails = useCallback(payload => dispatch(getOrganisationDetailsRoutine(payload)), [dispatch])

  useEffect(() => {
    fetchOrganisationDetails({ id })
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <OrganisationDetails id={id} />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Paper>
            <SectionTitle>
              {t('organisationsDetailsPage.tableHeadings.title')}
            </SectionTitle>
            <UsersList id={id} />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default OrganisationPage

export const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.palette.text.main};
`
