import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { propOr, pathOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import { getOrganisationLogoOrAvatar, hasOrganisationPointsEnabled, hasOrganisationIntegration } from 'src/utils/organisation'
import { getCountryNameByCode } from 'src/utils/country'
import { formatDate, FORMATS } from 'src/utils/date'

import { getOrganisationsDetailsById } from 'src/features/organisations/duck/selectors'

import Paper from 'src/components/atoms/Paper'
import BubbleLogo from 'src/components/BubbleLogo'
import Grid from 'src/components/atoms/Grid'

export const OrganisationDetails = (props) => {
  const { id } = props
  const { t } = useTranslation()
  const organisation = useSelector(state => getOrganisationsDetailsById(state, id))
  const organisationName = propOr('', 'name')(organisation)
  const hasPointSystem = hasOrganisationPointsEnabled(organisation)
  const getPointSystemDetailsOrDefault = prop => pathOr('-', ['settings', prop])(organisation)
  const getDetailsPropOrDefault = prop => propOr('-', prop)(organisation)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Paper>
          <OrganisationNameAndLogoContainer>
            <BubbleLogo avatarHeight={120} imageSrc={getOrganisationLogoOrAvatar(organisation)} />
            <OrganisationName>{organisationName}</OrganisationName>
          </OrganisationNameAndLogoContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Paper>
          <SectionContainer>
            <SectionTitle>
              {t('organisationsDetailsPage.detailsSection.sectionTitle')}
            </SectionTitle>
            <SectionDetailsContainer>
              <table width='100%'>
                <tbody>
                <DetailsRow
                  title={t('organisationsDetailsPage.detailsSection.country')}
                  text={getCountryNameByCode(getDetailsPropOrDefault('country'))}
                />
                <DetailsRow title={t('organisationsDetailsPage.detailsSection.city')} text={getDetailsPropOrDefault('city')} />
                <DetailsRow title={t('organisationsDetailsPage.detailsSection.address')} text={getDetailsPropOrDefault('address')} />
                <DetailsRow title={t('organisationsDetailsPage.detailsSection.createdAt')} text={formatDate(getDetailsPropOrDefault('createdAt'), FORMATS.slash)} />
                </tbody>
              </table>
            </SectionDetailsContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>
              {t('organisationsDetailsPage.systemSection.sectionTitle')}
            </SectionTitle>
            <SectionDetailsContainer>
              <table width='100%'>
                <tbody>
                  <DetailsRow
                    title={t('organisationsDetailsPage.systemSection.type')}
                    text={hasPointSystem ? t('organisationsDetailsPage.systemSection.points') : t('organisationsDetailsPage.systemSection.appreciations')}
                  />
                  <DetailsRow title={t('organisationsDetailsPage.systemSection.pointsGiveMinimum')} text={getPointSystemDetailsOrDefault('pointsGiveMinimum')} />
                </tbody>
              </table>
            </SectionDetailsContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>
              {t('organisationsDetailsPage.integrationsSection.sectionTitle')}
            </SectionTitle>
            <SectionDetailsContainer>
              <table width='100%'>
                <tbody>
                  <DetailsRow
                    title={t('organisationsDetailsPage.integrationsSection.slack')}
                    text={hasOrganisationIntegration('slack')(organisation) ? '✓' : '-'}
                  />
                </tbody>
              </table>
            </SectionDetailsContainer>
          </SectionContainer>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default OrganisationDetails

const DetailsRow = ({ title, text }) => (
  <tr>
    <td width='30%'>
      <SectionSubtitle>
        {title}
      </SectionSubtitle>
    </td>
    <td width='70%'>
      {text}
    </td>
  </tr>
)

const OrganisationNameAndLogoContainer = styled.div`
  text-align: center;
`

const OrganisationName = styled.div`
  box-sizing: border-box;
  color: ${props => props.theme.palette.text.main};
  font-size: 23px;
  font-weight: 600;
  width: 100%;
  margin-top: 20px;
`

export const SectionContainer = styled.div`
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
`

export const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.palette.text.main};
`

export const SectionSubtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme.palette.common.doveGray};
  word-break: keep-all;
`

export const SectionDetailsContainer = styled.div`
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-height: 63px;
  background-color: ${props => props.theme.palette.common.whisper};
  border-radius: 17px;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  
  td {
    padding: 5px;
    word-break: keep-all;
  }
`
