import React from 'react'
import styled from 'styled-components'
import PhotoFrame from 'src/assets/images/vectors/photoFrame.svg'

export const BubbleLogo = (props) => {
  const { imageSrc, avatarHeight } = props

  return (
    <BubbleUserAvatarContainer imageSrc={imageSrc} avatarHeight={avatarHeight}>
      <div className='bubble-avatar__photo-section'>
        <div className='bubble-avatar__photo-container'>
          <div className='bubble-avatar__photo' />
        </div>
      </div>
    </BubbleUserAvatarContainer>
  )
}

BubbleLogo.defaultProps = {
  avatarHeight: 40
}

export default BubbleLogo

const BubbleUserAvatarContainer = styled.div`
  display: inline-block;
  
  .bubble-avatar__photo-section {
    text-align: center;
    line-height: 1;
  }
  
  .bubble-avatar__photo-container {
    position: relative;
    display: inline-block;
  }
  
  .bubble-avatar__photo {
    background-image: url('${props => props.imageSrc}');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    font-size: 30px;
    height: ${props => props.avatarHeight}px;
    width: ${props => props.avatarHeight + 8}px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .bubble-avatar__photo::after {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    height: ${props => props.avatarHeight + 2}px;
    width: ${props => props.avatarHeight + 6}px;
    background-image: url('${PhotoFrame}');
    background-repeat: no-repeat;
  }
`
