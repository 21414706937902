import { pipe, propOr, ifElse, prop, always, pathOr, equals } from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { AVATARS } from 'src/utils/avatar'

const hasAvatarUrlAttached = pipe(propOr(null, 'avatarUrl'), isNotNilOrEmpty)
const hasAvatarAttached = pipe(propOr(null, 'avatar'), isNotNilOrEmpty)
const getAvatarImage = avatarName => AVATARS[avatarName]

export const getOrganisationLogoOrAvatar = pipe(
  ifElse(
    hasAvatarUrlAttached,
    prop('logoUrl'),
    ifElse(
      hasAvatarAttached,
      pipe(prop('avatar'), getAvatarImage),
      pipe(always('avatar_1'), getAvatarImage)
    )
  )
)

export const hasOrganisationPointsEnabled = pipe(
  pathOr(false, ['settings', 'pointsEnabled']),
  equals(true)
)

export const hasOrganisationIntegration = integrationName => pipe(
  pathOr({}, ['integrations', integrationName]),
  isNotNilOrEmpty
)
