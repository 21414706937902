import { KEYS } from 'src/utils/localStorage'
import LocalStorageService from 'src/services/LocalStorageService'
import { LANGUAGES } from 'src/utils/locales'
import {
  toUpper,
  head,
  pipe,
  juxt,
  take,
  splitAt,
  last,
  toLower,
  join
} from 'ramda'

export const withPossessiveS = str => {
  const isSLastLetter = str.endsWith('s')
  const isEnglish =
    LocalStorageService.get(KEYS.selectedLanguage) === LANGUAGES.en
  const getPossessive = isSLastLetter ? "'" : "'s"

  return isEnglish ? str + getPossessive : str
}

export const getCapitalizedFirstLetter = pipe(head, toUpper)

export const capitalizeFirst = pipe(
  juxt([pipe(take(1), toUpper), pipe(splitAt(1), last, toLower)]),
  join('')
)
