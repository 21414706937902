import { propOr, pipe } from 'ramda'
import { createSelector } from 'reselect'
import { transformToArray } from 'src/utils/redux'

export const selectUsers = state => state.users

export const getOrganisationUsers = createSelector(
  selectUsers,
  pipe(propOr({}, 'users'), transformToArray)
)

export const getOrganisationUsersMeta = createSelector(
  selectUsers,
  propOr({}, 'metaUsers')
)
