import React from 'react'
import AuthLayout from 'src/theme/AuthLayout'
import { Router } from '@reach/router'
import PrivateRoute from 'src/components/PrivateRoute'
import PATHS from 'src/utils/paths'
import withPrivatePage from 'src/hoc/withPrivatePage'
import Organisations from 'src/features/organisations/Organisations'
import OrganisationPage from 'src/features/organisations/OrganisationPage'
import PaddingContainer from 'src/components/PaddingContainer'

export const Workspace = props => {
  return (
    <AuthLayout>
      <PaddingContainer>
        <Router>
          <PrivateRoute
            path={PATHS.dashboard}
            component={() => <div>Dashboard</div>}
          />
          <PrivateRoute
            path={PATHS.organisations}
            component={Organisations}
          />
          <PrivateRoute
            path={PATHS.organisationPage}
            component={OrganisationPage}
          />
        </Router>
      </PaddingContainer>
    </AuthLayout>
  )
}

export default withPrivatePage(Workspace)
