import React from 'react'
import styled from 'styled-components'
import { getUserPhotoOrAvatar } from 'src/utils/user'

export const UserAvatar = props => {
  const { user } = props

  return <AvatarContainer imageSrc={getUserPhotoOrAvatar(user)} />
}

export default UserAvatar

export const AvatarContainer = styled.div`
  background-image: url(${props => props.imageSrc});
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background-color: ${props => props.theme.palette.common.white};
  color: ${props => props.theme.palette.secondary.main};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: ${props => props.theme.shadows.main};
`
