import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PATHS from 'src/utils/paths'

import {
  getIsAuthorised,
  isAuthLoaded
} from 'src/features/auth/duck/selectors'

import Loader from 'src/components/atoms/Loader'

const PrivateRoute = ({
  component: Component,
  location,
  navigate,
  restricted,
  ...rest
}) => {
  //  REDUX
  const isAuthorised = useSelector(getIsAuthorised)
  const isLoaded = useSelector(isAuthLoaded)

  useEffect(() => {
    !isAuthorised && navigate(PATHS.logIn)
    restricted &&
      isLoaded &&
      isAuthorised &&
      navigate(PATHS.dashboard)
  }, [])

  return isAuthorised ? <Component {...rest} location={location} /> : <Loader />
}

export default PrivateRoute
