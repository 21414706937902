import React from 'react'
import { useTranslation } from 'react-i18next'

import OrganisationsList from 'src/features/organisations/components/OrganisationsList'
import Paper from 'src/components/atoms/Paper'
import Grid from 'src/components/atoms/Grid'
import PageTitle from 'src/components/atoms/PageTitle'

export const Organisations = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <PageTitle>{t('organisationsPage.pageTitle')}</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paper variant='outlined'>
            <OrganisationsList />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default Organisations
