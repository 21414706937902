import { getNames, registerLocale } from 'i18n-iso-countries'
import { LANGUAGES } from 'src/utils/locales'
import { KEYS } from 'src/utils/localStorage'
import LocalStorageService from 'src/services/LocalStorageService'
import { map, applySpec, pipe, identity, keys, sortBy, prop, propOr } from 'ramda'

registerLocale(require('i18n-iso-countries/langs/en.json'))
registerLocale(require('i18n-iso-countries/langs/pl.json'))

const generateOption = countries =>
  applySpec({
    value: identity,
    label: key => countries[key]
  })

export const countryOptions = () => {
  const countries = getNames(
    LocalStorageService.get(KEYS.selectedLanguage) || LANGUAGES.en
  )

  return pipe(
    keys,
    map(generateOption(countries)),
    sortBy(prop('label'))
  )(countries)
}

export const getCountryNameByCode = key => {
  const countries = getNames(
    LocalStorageService.get(KEYS.selectedLanguage) || LANGUAGES.en
  )

  return propOr(key, key, countries)
}
