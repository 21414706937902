import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { map } from 'ramda'
import { getCountryNameByCode } from 'src/utils/country'
import { formatDate, FORMATS } from 'src/utils/date'
import { Link } from 'gatsby'

import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableHead,
  TableRow,
  TableFooter
} from 'src/components/atoms/Table/Table'
import Pagination from 'src/components/atoms/Table/Pagination'
import EmptyState from 'src/components/EmptyState'
import { getOrganisationsRoutine } from 'src/features/organisations/duck/actions'
import { getOrganisations, getOrganisationsMeta } from 'src/features/organisations/duck/selectors'

export const OrganisationsList = props => {
  const { t } = useTranslation()

  //  REDUX
  const dispatch = useDispatch()
  const organisations = useSelector(getOrganisations)
  const pagination = useSelector(getOrganisationsMeta)
  const fetchOrganisations = useCallback(
    query => dispatch(getOrganisationsRoutine(query)),
    [dispatch]
  )

  const OrganisationRow = organisation => (
    <TableRow key={organisation.id}>
      <TableCell>
        <Link to={`/app/organisations/${organisation.id}`}>{organisation.name}</Link>
      </TableCell>
      <TableCell>{formatDate(organisation.createdAt, FORMATS.slash)}</TableCell>
      <TableCell>{getCountryNameByCode(organisation.country)}</TableCell>
      <TableCell>{organisation.city}</TableCell>
      <TableCell>{organisation.address}</TableCell>
    </TableRow>
  )

  const TableEmptyState = (
    <TableRow>
      <TableCell>
        <EmptyStateContainer>
          <EmptyState text={t('organisationsPage.emptyState')} />
        </EmptyStateContainer>
      </TableCell>
    </TableRow>
  )

  const renderRows = map(OrganisationRow)(organisations)

  return (
    <Table aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableHeaderCell>
            {t('organisationsPage.tableHeadings.name')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsPage.tableHeadings.createdAt')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsPage.tableHeadings.country')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsPage.tableHeadings.city')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('organisationsPage.tableHeadings.address')}
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>{organisations.length > 0 ? renderRows : TableEmptyState}</TableBody>
      <TableFooter>
        <TableRow>
          <Pagination
            pagination={pagination}
            fetchRecords={fetchOrganisations}
            additionalQuery={{
              order: {
                by: 'created_at',
                dir: 'DESC'
              }
            }}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default OrganisationsList

const EmptyStateContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
